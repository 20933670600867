import * as React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { graphql } from 'gatsby'
import Link from '../components/Link'
import Seo from '../components/SEO'
import excerpt from '../utils/excerpt'

const Blogs = ({ data }) => {
  const posts = data.allWPost.nodes
  return (
    <>
      <Seo title="Blog" description="Pairing blog list" />
      <div className="mx-3 md:mx-20 mt-12">
        <div className="mx-auto w-full my-8 text-center">
          <h1 className="text-6xl font-bold font-paragraph">Blog</h1>
          <p className="text-center w-full mx-auto max-w-3xl font-paragraph text-2xl text-teal-dark">
            Welcome to the Pairing blog. We are building in public - so you
            learn copy, adapt and do it even better. We are creators. We cannot
            help it. We asked ourselves: what if we share the whole software
            creation process from A - Z. Follow us on the journey of building
            our own app and realasing it to the public.
          </p>
        </div>

        <div className="flex flex-wrap justify-center first:max-w-xl ">
          {!_.isEmpty(posts) || !posts ? (
            posts?.map((post) => (
              <Link
                to={`/blog/${post.slug}`}
                className="max-w-md dark:bg-dark_bg-lighter m-4 md:m-6 dark:border-dark_bg-normal rounded-md cursor-pointer"
                key={post.id}
              >
                <img
                  className="w-full md:h-64 rounded-md object-cover"
                  src={post.jetpack_featured_media_url}
                  alt="Blog featured"
                />

                <h3 className="text-2xl font-bold mt-4 font-display">
                  {post.title.rendered}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: excerpt(post.excerpt?.rendered, 30),
                  }}
                >
                  {}
                </div>
                <span className="font-normal text-teal-dark font-heading">
                  {moment(post.date).format('LL')}
                </span>
              </Link>
            ))
          ) : (
            <div class="flex mx-auto items-center justify-center space-x-2 animate-pulse">
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
              <div class="w-8 h-8 bg-blue-400 rounded-full"></div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Blogs

export const pageQuery = graphql`
  query {
    allWPost {
      nodes {
        id
        title {
          rendered
        }
        date
        jetpack_featured_media_url
        type
        slug
        content {
          rendered
        }
        excerpt {
          rendered
        }
        categories
      }
    }
  }
`
